import { useState } from "react"
import { resetPassword } from "src/api"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"
export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const execute = async (token, password) => {
    try {
      trackCustomEvent(getAPI_CALL_STARTED("RESET_PASSWORD"))

      setLoading(true)
      setError(undefined)
      const data = await resetPassword(token, password)
      return data
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, resetPassword: execute }
}
